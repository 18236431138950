// download.js
// Handles the ダウンロード command from context menu

import { useState } from 'react';
import { authRequest } from './auth.js';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { getRecursiveFolderInfo } from './rest.js';

export const downloadFile = async uuid => {
  const fileResponse = await authRequest({
    method: 'GET',
    url: `/api/datamanage/detail/file/${uuid}`,
  });

  let blobResponse = await axios.get(fileResponse.data['signed_url'], {
    responseType: 'blob',
  });
  const url = window.URL.createObjectURL(new Blob([blobResponse.data]));
  const link = document.createElement('a');
  link.href = url;
  let fileName = fileResponse.data.name;
  fileName = fileName === '' ? '無名のファイル' : fileName;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFolder = async (
  uuid,
  setProgressValue,
  setSnackbarMessage,
  cancelDownloadRef
) => {
  /* ファイル情報をまとめてダウンロードしてzipにする */

  setProgressValue(0);
  const folderResponse = await getRecursiveFolderInfo(uuid);
  const zip = new JSZip();
  let rootName = folderResponse.data.name;

  const folderCount = folderResponse.data.folders.length;
  const fileCount = folderResponse.data.files.length;
  const totalCount = folderCount + fileCount;

  // If the folder is empty, return immediately
  if (totalCount === 0) {
    return;
  }

  rootName = rootName === '' ? '無題のフォルダ' : rootName;
  const folder = zip.folder(rootName);
  let subfolderCount = 0;
  const downloadAndZipRecursive = async (folderData, zipFolder) => {
    const usedFiles = {};
    const usedFolders = {};
    let progressCount = 0;
    const fileCount = folderData.files.length + folderData.folders.length;

    // Iterate all files in the folder
    for (const file of folderData.files) {
      // If download was cancelled, return immediately
      if (cancelDownloadRef.current) {
        console.warn('Download was cancelled, Abort');
        return;
      }
      progressCount += 1;

      let prog = 0;
      if (totalCount > 1) {
        prog = Math.floor((subfolderCount / totalCount) * 100);
      } else {
        // if only one series
        prog = Math.floor((progressCount / fileCount) * 100);
      }

      setProgressValue(prog);
      const blobResponse = await axios.get(file['signed_url'], {
        responseType: 'blob',
      });
      let fileName = file.name;
      console.log(fileName);
      fileName = fileName === '' ? '無題のファイル' : fileName;
      if (Object.keys(usedFiles).includes(fileName)) {
        let fileNameList = fileName.split('.');
        if (fileNameList.length >= 2) {
          fileNameList[fileNameList.length - 2] += `(${usedFiles[fileName]})`;
          const newFileName = fileNameList.join('.');
          zipFolder.file(newFileName, blobResponse.data);
        } else {
          const newFileName = fileName + `(${usedFiles[fileName]})`;
          zipFolder.file(newFileName, blobResponse.data);
        }
        usedFiles[fileName] += 1;
      } else {
        zipFolder.file(fileName, blobResponse.data);
        usedFiles[fileName] = 1;
      }
      setSnackbarMessage(
        `ダウンロード中: (${progressCount}/${fileCount}インスタンス数 -
                      ${subfolderCount - 1}/${totalCount}シリーズ数)`
      );
    }
    subfolderCount += 1;

    for (const folder of folderData.folders) {
      let folderName = folder.name;
      folderName = folderName === '' ? '無題のフォルダ' : folderName;
      if (Object.keys(usedFolders).includes(folderName)) {
        const newFolderName = folderName + `(${usedFolders[folderName]})`;
        const zipFolderChild = zipFolder.folder(newFolderName);
        usedFolders[folderName] += 1;
        await downloadAndZipRecursive(folder, zipFolderChild);
      } else {
        const zipFolderChild = zipFolder.folder(folderName);
        usedFolders[folderName] = 1;
        await downloadAndZipRecursive(folder, zipFolderChild);
      }
    }
  };
  await downloadAndZipRecursive(folderResponse.data, folder);

  // Check if the download was cancelled before generating the zip
  if (cancelDownloadRef.current) {
    console.warn('Cancel zip file generation');
    return;
  }

  zip.generateAsync({ type: 'blob' }).then(content => {
    saveAs(content, folderResponse.data.name + '.zip');
  });
};
